import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index'

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
console.log('process.env.NODE_ENV',process.env.NODE_ENV)

const router = new VueRouter({
    mode: 'history',
    base:process.env.NODE_ENV==="development" ? '':'',
    routes
})

router.beforeEach((to, from, next) => {
    console.log('理由进入',to)
    console.log(process.env.NODE_ENV)
    if (to.meta.title) {
        document.title = to.meta.title
      }
    store.commit("changeTabActive",to.path)
    // if(to.query.token){   // 路由有带过来token
    //     sessionStorage.setItem('token',to.query.token)
    // }
    next()
})

export default router