import Page404 from "../pages/page404/index.vue";
const routes = [
  {
    name: "pcIndex",
    path: "/",
    meta: { index: 1, title: "首页" },
    component: (resolve) => require(["@/pages/pc/index.vue"], resolve),
  },
  {
    name: "levelIntroduce",
    path: "/levelIntroduce",
    meta: { index: 1, title: "等级管理" },
    component: (resolve) =>
      require(["@/pages/level/levelIntroduce.vue"], resolve),
  },
  {
    name: "buyMember",
    path: "/buyMember",
    meta: { index: 1, title: "VIP会员" },
    component: (resolve) => require(["@/pages/level/buyMember.vue"], resolve),
  },
  {
    name: "profitMain",
    path: "/profitMain",
    meta: { index: 1, title: "收益管理" },
    component: (resolve) => require(["@/pages/profit/profitMain.vue"], resolve),
  },
  {
    name: "hasProfit",
    path: "/hasProfit",
    meta: { index: 1, title: "提现" },
    component: (resolve) => require(["@/pages/profit/hasProfit.vue"], resolve),
  },
  {
    name: "exchangeCoin",
    path: "/exchangeCoin",
    meta: { index: 1, title: "兑换" },
    component: (resolve) =>
      require(["@/pages/profit/exchangeCoin.vue"], resolve),
  },
  {
    name: "giftProfit",
    path: "/giftProfit",
    meta: { index: 1, title: "礼物记录" },
    component: (resolve) => require(["@/pages/profit/giftProfit.vue"], resolve),
  },
  {
    name: "weekProfit",
    path: "/weekProfit",
    meta: { index: 1, title: "收益记录" },
    component: (resolve) => require(["@/pages/profit/weekProfit.vue"], resolve),
  },
  {
    name: "otayoniiInfo",
    path: "/otayoniiInfo",
    meta: { index: 1, title: "礼物宝贝明细" },
    component: (resolve) =>
      require(["@/pages/profit/otayoniiInfo.vue"], resolve),
  },
  {
    name: "addInfo",
    path: "/addInfo",
    meta: { index: 1, title: "信息完善" },
    component: (resolve) => require(["@/pages/profit/addInfo.vue"], resolve),
  },
  {
    name: "familyMain",
    path: "/familyMain",
    meta: { index: 1, title: "家族管理" },
    component: (resolve) => require(["@/pages/family/familyMain.vue"], resolve),
  },
  {
    name: "salaryMain",
    path: "/salaryMain",
    meta: { index: 1, title: "主播数据" },
    component: (resolve) => require(["@/pages/family/salaryMain.vue"], resolve),
  },
  {
    name: "grant",
    path: "/grant",
    meta: { index: 1, title: "工资发放" },
    component: (resolve) => require(["@/pages/family/grant.vue"], resolve),
  },
  {
    name: "openLiveRate",
    path: "/openLiveRate",
    meta: { index: 1, title: "开播率" },
    component: (resolve) =>
      require(["@/pages/family/openLiveRate.vue"], resolve),
  },
  {
    name: "payroll",
    path: "/payroll",
    meta: { index: 1, title: "宝贝分配" },
    component: (resolve) => require(["@/pages/family/payroll.vue"], resolve),
  },
  {
    name: "vjSalary",
    path: "/vjSalary",
    meta: { index: 1, title: "主播薪水" },
    component: (resolve) => require(["@/pages/family/vjSalary.vue"], resolve),
  },
  {
    name: "micTime",
    path: "/micTime",
    meta: { index: 1, title: "主播麦时" },
    component: (resolve) => require(["@/pages/family/micTime.vue"], resolve),
  },
  {
    name: "anchroOtayonii",
    path: "/anchroOtayonii",
    meta: { index: 1, title: "主播宝贝明细" },
    component: (resolve) =>
      require(["@/pages/family/anchroOtayonii.vue"], resolve),
  },
  {
    name: "anchorExchange",
    path: "/anchorExchange",
    meta: { index: 1, title: "主播提现明细" },
    component: (resolve) =>
      require(["@/pages/family/anchorExchange.vue"], resolve),
  },
  {
    name: "anchorCenter",
    path: "/anchorCenter",
    meta: { index: 1, title: "主播中心" },
    component: (resolve) =>
      require(["@/pages/familyManagement/anchorCenter.vue"], resolve),
  },
  {
    name: "familyManagement",
    path: "/familyManagement",
    meta: { index: 1, title: "家族管理" },
    component: (resolve) =>
      require(["@/pages/familyManagement/index.vue"], resolve),
  },
  {
    name: "coinExchange",
    path: "/coinExchange",
    meta: { index: 1, title: "金币兑换" },
    component: (resolve) =>
      require(["@/pages/familyManagement/coinExchange.vue"], resolve),
  },

  {
    name: "anchorList",
    path: "/anchorList",
    meta: { index: 1, title: "主播列表" },
    component: (resolve) =>
      require(["@/pages/familyManagement/anchorList.vue"], resolve),
  },
  {
    name: "liveDetails",
    path: "/liveDetails",
    meta: { index: 1, title: "直播详情" },
    component: (resolve) =>
      require(["@/pages/familyManagement/liveDetails.vue"], resolve),
  },
  {
    name: "liveDetails2",
    path: "/liveDetails2",
    meta: { index: 1, title: "直播详情" },
    component: (resolve) =>
      require(["@/pages/familyManagement/liveDetails2.vue"], resolve),
  },
  {
    name: "personalData",
    path: "/personalData",
    meta: { index: 1, title: "个人数据" },
    component: (resolve) =>
      require(["@/pages/familyManagement/personalData.vue"], resolve),
  },
  {
    name: "withdrawal",
    path: "/withdrawal",
    meta: { index: 1, title: "提现" },
    component: (resolve) =>
      require(["@/pages/familyManagement/withdrawal.vue"], resolve),
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: { index: 1, title: "提现" },
    component: (resolve) =>
      require(["@/pages/merchants/withdraw.vue"], resolve),
  },

  {
    name: "withdrawalRecords",
    path: "/withdrawalRecords",
    meta: { index: 1, title: "提现记录" },
    component: (resolve) =>
      require(["@/pages/familyManagement/withdrawalRecords.vue"], resolve),
  },

  {
    name: "knapsackList",
    path: "/knapsackList",
    meta: { index: 1, title: "特效明细" },
    component: (resolve) =>
      require(["@/pages/family/knapsackList.vue"], resolve),
  },
  {
    name: "roomCm",
    path: "/roomCm",
    meta: { index: 1, title: "房间流水" },
    component: (resolve) => require(["@/pages/family/roomCm.vue"], resolve),
  },
  {
    name: "fail",
    path: "/failMain",
    meta: { index: 1, title: "登录失效" },
    component: (resolve) => require(["@/pages/fail/failMain.vue"], resolve),
  },
  {
    name: "index",
    path: "/index",
    meta: { index: 1, title: "下载页" },
    component: (resolve) => require(["@/pages/download/index.vue"], resolve),
  },
  {
    name: "agree",
    path: "/agree",
    meta: { index: 1, title: "用户协议" },
    component: (resolve) => require(["@/pages/user/agree.vue"], resolve),
  },
  {
    name: "conceal",
    path: "/conceal",
    meta: { index: 1, title: "隐私政策" },
    component: (resolve) => require(["@/pages/user/conceal.vue"], resolve),
  },
  {
    name: "anchor",
    path: "/anchor",
    meta: { index: 1, title: "主播协议" },
    component: (resolve) => require(["@/pages/user/anchor.vue"], resolve),
  },
  {
    name: "recharge",
    path: "/recharge",
    meta: { index: 1, title: "充值协议" },
    component: (resolve) => require(["@/pages/user/recharge.vue"], resolve),
  },
  // {
  //   name: "intregal",
  //   path: "/intregal",
  //   meta: { index: 1, title: "领取公积金" },
  //   component: (resolve) => require(["@/pages/activity/intregal.vue"], resolve),
  // },
  {
    name: "voucherCenter",
    path: "/voucherCenter",
    meta: { index: 1, title: "充值中心" },
    component: (resolve) =>
      require(["@/pages/user/voucherCenter.vue"], resolve),
  },
  {
    name: "goldenEggs",
    path: "/goldenEggs",
    meta: { index: 1, title: "砸金蛋" },
    component: (resolve) =>
      require(["@/pages/gameActivities/goldenEggs.vue"], resolve),
  },
  {
    name: "lucky-wheel",
    path: "/lucky-wheel",
    meta: { index: 1, title: "抽奖大转盘" },
    component: (resolve) =>
      require(["@/pages/gameActivities/lucky-wheel.vue"], resolve),
  },
  {
    name: "paySuccess",
    path: "/paySuccess",
    meta: { index: 1, title: "抽奖大转盘" },
    component: (resolve) => require(["@/pages/user/paySuccess.vue"], resolve),
  },
  {
    name: "firstChange",
    path: "/firstChange",
    meta: { index: 1, title: "首充活动" },
    component: (resolve) =>
      require(["@/pages/activity/firstChange.vue"], resolve),
  },
  {
    name: "contactService",
    path: "/contactService",
    meta: { index: 1, title: "联系客服" },
    component: (resolve) =>
      require(["@/pages/activity/contactService.vue"], resolve),
  },
  {
    name: "millionActivity",
    path: "/millionActivity",
    meta: { index: 1, title: "热门活动限时返场" },
    component: (resolve) =>
      require(["@/pages/activity/millionActivity.vue"], resolve),
  },
  {
    name: "inFamily",
    path: "/inFamily",
    meta: { index: 1, title: "公会招募" },
    component: (resolve) =>
      require(["@/pages/activity/in_family.vue"], resolve),
  },
  {
    name: "dayFirstCharge",
    path: "/dayFirstCharge",
    meta: { index: 1, title: "每日首充活动" },
    component: (resolve) =>
      require(["@/pages/activity/dayFirstCharge.vue"], resolve),
  },
  {
    name: "51",
    path: "/51",
    meta: { index: 1, title: "热门活动" },
    component: (resolve) => require(["@/pages/activity/51.vue"], resolve),
  },
  {
    name: "certificationMain",
    path: "/certificationMain",
    meta: { index: 1, title: "实名认证" },
    component: (resolve) =>
      require(["@/pages/certification/certificationMain.vue"], resolve),
  },
  {
    name: "certificationResult",
    path: "/certificationResult",
    meta: { index: 1, title: "实名认证结果" },
    component: (resolve) =>
      require(["@/pages/certification/certificationResult.vue"], resolve),
  },
  {
    name: "anchorList",
    path: "/anchorList",
    meta: { index: 1, title: "主播列表" },
    component: (resolve) => require(["@/pages/family/anchorList.vue"], resolve),
  },
  {
    name: "imageGenerationPage",
    path: "/imageGenerationPage",
    meta: { index: 1, title: "活动页" },
    component: (resolve) =>
      require(["@/pages/activity/imageGenerationPage.vue"], resolve),
  },
  {
    name: "imageGenerationPage2",
    path: "/imageGenerationPage2",
    meta: { index: 1, title: "活动页" },
    component: (resolve) =>
      require(["@/pages/activity/imageGenerationPage2.vue"], resolve),
  },
  {
    name: "intregal",
    path: "/intregal",
    meta: { index: 1, title: "积分活动" },
    component: (resolve) =>
      require(["@/pages/activity/intregal/index.vue"], resolve),
  },
  {
    name: "intregalRule",
    path: "/intregalRule",
    meta: { index: 1, title: "领取规则" },
    component: (resolve) =>
      require(["@/pages/activity/intregal/rule.vue"], resolve),
  },

  {
    name: "convention",
    path: "/convention",
    meta: { index: 1, title: "文明公约" },
    component: (resolve) =>
      require(["@/pages/activity/convention.vue"], resolve),
  },
  {
    name: "newYearsDay",
    path: "/newYearsDay",
    meta: { index: 1, title: "元旦活动" },
    component: (resolve) =>
      require(["@/pages/activity/newYearsDay.vue"], resolve),
  },
  {
    name: "invitingActivities",
    path: "/invitingActivities",
    meta: { index: 1, title: "热门活动" },
    component: (resolve) =>
      require(["@/pages/activity/invitingActivities.vue"], resolve),
  },
  {
    name: "myInviter",
    path: "/myInviter",
    meta: { index: 1, title: "我的邀请人" },
    component: (resolve) =>
      require(["@/pages/activity/myInviter.vue"], resolve),
  },
  {
    name: "redEnvelopes",
    path: "/redEnvelopes",
    meta: { index: 1, title: "百万红包" },
    component: (resolve) =>
      require(["@/pages/activity/redEnvelopes.vue"], resolve),
  },

  {
    name: "redEnvelopeRain",
    path: "/redEnvelopeRain",
    meta: { index: 1, title: "百万红包大派送" },
    component: (resolve) =>
      require(["@/pages/activity/redEnvelopeRain.vue"], resolve),
  },

  {
    name: "levelDescription",
    path: "/levelDescription",
    meta: { index: 1, title: "等级说明" },
    component: (resolve) =>
      require(["@/pages/level/levelDescription.vue"], resolve),
  },
  {
    name: "fileUpload",
    path: "/fileUpload",
    meta: { index: 1, title: "文件上传" },
    component: (resolve) => require(["@/pages/pc/fileUpload.vue"], resolve),
  },
  {
    name: "merchantCenter",
    path: "/merchantCenter",
    meta: { index: 1, title: "商户中心" },
    component: (resolve) => require(["@/pages/pc/merchantCenter.vue"], resolve),
  },
  {
    name: "transferpage",
    path: "/transferpage",
    meta: { index: 1, title: "中转页" },
    component: (resolve) => require(["@/pages/h5/transferpage.vue"], resolve),
  },

  {
    path: "*",
    component: Page404, //404页面
  },
];
export default routes;
